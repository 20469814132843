<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      title="Ligenübersicht"
      color="primary"
    >
      <v-data-table
        :headers="[
          { text: 'Liga', value: 'name', sortable: false, align: 'right' },
          { text: 'Mannschaften', value: 'teams', sortable: false, align: 'center' },
          { text: 'Turner', value: 'athletes', sortable: false, align: 'center' },
          { text: 'Kampfrichter', value: 'judges', sortable: false, align: 'center' },
          { text: 'Begegnungen', value: 'events', sortable: false, align: 'center' },
          { text: 'Zuschauer', value: 'zuschauer', sortable: false, align: 'center' },
        ]"
        :items="lstat"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item.zuschauer="{item}">
          {{ item.zuschauer.e > 0 ? item.zuschauer.z / item.zuschauer.e : 0 | float2_0 }} ({{ item.zuschauer.e }})
        </template>
      </v-data-table>
    </base-material-card>
    <base-material-card
      title="Sprungübersicht"
      color="primary"
    >
      <v-row>
        <v-col cols="4">
          <v-data-table
            v-model="sprungligen"
            :headers="[
              { text: 'Liga', value: 'name' }
            ]"
            :items="ligen"
            :items-per-page="-1"
            hide-default-footer
            show-select
            item-key="_id"
            :mobile-breakpoint="-1"
          />

          <apexchart type="pie" :options="chartoptions" :series="series" />
        </v-col>
        <v-col cols="8">
          <v-data-table
            :headers="[
              { text: 'Sprung', value: 'nr', align: 'right' },
              { text: 'Anzahl', value: 'count', align: 'center' }
            ]"
            :items="sprungstat"
            :items-per-page="-1"
            hide-default-footer
            sort-by="nr"
            :mobile-breakpoint="-1"
          >
            <template #item.nr="{item}">
              {{ item.name }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import Sprungtabelle from '@/views/components/stb_m_2021/content/input/bei/sprungtabelle'

export default {
  name: 'stats',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    sprungligen: []
  }),

  computed: {
    ligen () {
      return this.EventFind.slice().sort((a, b) => a.order < b.order ? -1 : 1)
    },
    lstat () {
      const tmp = this.ligen.map(l => ({
        name: l.name,
        teams: l.teams?.length || 0,
        athletes: (l.teams || []).reduce((acc, curr) => acc + (curr.athletes?.length || 0), 0),
        judges: (l.teams || []).reduce((acc, curr) => acc + (curr.judges?.length || 0), 0),
        events: l.events?.length || 0,
        zuschauer: (l.events || []).reduce((acc, curr) => { if (curr.info?.zuschauer) { acc.e++; acc.z += curr.info.zuschauer } return acc }, { z: 0, e: 0 })
      }))

      tmp.push({
        name: 'Gesamt',
        teams: tmp.reduce((acc, curr) => acc + curr.teams, 0),
        athletes: tmp.reduce((acc, curr) => acc + curr.athletes, 0),
        judges: tmp.reduce((acc, curr) => acc + curr.judges, 0),
        events: tmp.reduce((acc, curr) => acc + curr.events, 0),
        zuschauer: tmp.reduce((acc, curr) => { acc.e += curr.zuschauer.e; acc.z += curr.zuschauer.z; return acc }, { z: 0, e: 0 })
      })

      return tmp
    },
    spruenge () {
      return this.ligen.reduce((acc, curr) => {
        (curr.events || []).forEach(e => {
          (e.teams || []).forEach(t => {
            (t.athletes || []).forEach(a => {
              (a.results || []).forEach(r => {
                if (r._sprung) acc.push({ _sprung: r._sprung, _liga: curr._id })
              })
            })
          })
        })
        return acc
      }, [])
    },
    sprungstat () {
      const tmp = this.spruenge.reduce((acc, curr) => {
        if (!acc[curr._sprung]) acc[curr._sprung] = 1
        else acc[curr._sprung]++

        if (!this.sprungligen.find(l => l._id === curr._liga)) acc[curr._sprung]--

        return acc
      }, {})

      return Object.keys(tmp).map(nr => ({
        nr: parseInt(nr),
        name: Sprungtabelle.find(s => s._id === parseInt(nr))?.beschreibung,
        count: tmp[nr]
      }))
    },
    chartdata () {
      const tmp = this.sprungstat.slice().filter(a => a.count > 0).sort((a, b) => a.count > b.count ? -1 : 1).map(a => ({ name: a.name, count: a.count }))
      const gesamt = tmp.reduce((acc, curr) => acc + curr.count, 0)
      const sonst = tmp.filter(t => t.count <= gesamt * 0.02).reduce((acc, curr) => acc + curr.count, 0)
      return [...tmp.filter(t => t.count > gesamt * 0.02), { name: 'Sonstige', count: sonst }]
    },
    chartoptions () {
      return {
        labels: this.chartdata.map(d => d.name)
      }
    },
    series () {
      return this.chartdata.map(d => d.count)
    }
  },

  apollo: {
    EventFind: {
      query: gql`query($parent: UUID!) {
        EventFind(parent: $parent) {
          _id
          ... on StbM2021League {
            name
            shortName
            order
            teams {
              _team
              athletes { _person }
              judges { _person }
            }
            events {
              _id
              judges { person { _id givenName familyName }}
              teams {
                _team
                athletes {
                  results { _discipline _sprung }
                }
              }
              info { zuschauer }
            }
          }
        }
      }`,
      variables () {
        return {
          parent: this.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
