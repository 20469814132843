var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"title":"Ligenübersicht","color":"primary"}},[_c('v-data-table',{attrs:{"headers":[
        { text: 'Liga', value: 'name', sortable: false, align: 'right' },
        { text: 'Mannschaften', value: 'teams', sortable: false, align: 'center' },
        { text: 'Turner', value: 'athletes', sortable: false, align: 'center' },
        { text: 'Kampfrichter', value: 'judges', sortable: false, align: 'center' },
        { text: 'Begegnungen', value: 'events', sortable: false, align: 'center' },
        { text: 'Zuschauer', value: 'zuschauer', sortable: false, align: 'center' },
      ],"items":_vm.lstat,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.zuschauer",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("float2_0")(item.zuschauer.e > 0 ? item.zuschauer.z / item.zuschauer.e : 0))+" ("+_vm._s(item.zuschauer.e)+") ")]}}])})],1),_c('base-material-card',{attrs:{"title":"Sprungübersicht","color":"primary"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-data-table',{attrs:{"headers":[
            { text: 'Liga', value: 'name' }
          ],"items":_vm.ligen,"items-per-page":-1,"hide-default-footer":"","show-select":"","item-key":"_id","mobile-breakpoint":-1},model:{value:(_vm.sprungligen),callback:function ($$v) {_vm.sprungligen=$$v},expression:"sprungligen"}}),_c('apexchart',{attrs:{"type":"pie","options":_vm.chartoptions,"series":_vm.series}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{attrs:{"headers":[
            { text: 'Sprung', value: 'nr', align: 'right' },
            { text: 'Anzahl', value: 'count', align: 'center' }
          ],"items":_vm.sprungstat,"items-per-page":-1,"hide-default-footer":"","sort-by":"nr","mobile-breakpoint":-1},scopedSlots:_vm._u([{key:"item.nr",fn:function({item}){return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }